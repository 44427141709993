<template>
  <error-template
    img-url="https://storage.googleapis.com/tillhub-api-images/dashboard/illustrations/error-pages/403.svg"
    :title="$t('pages.error.403.title')"
    :sub-title="$t('pages.error.403.subtitle.main')"
  >
    <template #subtitle>
      <div>
        <div>
          {{ $t('pages.error.403.subtitle.description.start', { resource }) }}
        </div>
        <div>
          {{ $t('pages.error.403.subtitle.description.middle') }}
        </div>
        <div>
          {{ $t('pages.error.403.subtitle.description.end') }}: {{ resource }} -
          {{ actions }}
        </div>
      </div>
    </template>
    <el-button type="primary" @click="goHome">
      {{ $t('pages.error.403.button') }}
    </el-button>
  </error-template>
</template>

<script>
import qs from 'qs'
import ErrorTemplate from './components/error-template'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'
export default {
  name: 'Page403',
  components: { ErrorTemplate },
  setup() {
    const appConfigStore = useAppConfigStore()
    const { navigation } = storeToRefs(appConfigStore)

    return { navigation }
  },
  computed: {
    resource() {
      const navigationItem = this.navigation.find(
        ({ name }) => name === this.queryParams.resource
      )
      return navigationItem?.key ? this.$t(navigationItem.key) : ''
    },
    translations() {
      return {
        archive: this.$t('common.actions.archive'),
        create: this.$t('common.actions.create'),
        delete: this.$t('common.actions.delete'),
        export: this.$t('common.actions.export'),
        read: this.$t('common.actions.read'),
        update: this.$t('common.actions.update')
      }
    },
    actions() {
      return (this.queryParams.actions || [])
        .map((action) => this.translations[action])
        .join(', ')
    },
    queryParams() {
      return qs.parse(this.$route.query)
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped></style>
